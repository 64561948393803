<template>
  <div class="toastification">
    <div class="d-flex flex-row justify-content-between align-items-center">
      <b-avatar
        variant="warning"
        size="1.8rem"
        class="mr-75 flex-shrink-0"
      >
        <AlertTriangleIcon size="15" />
      </b-avatar>
      <div class="d-flex flex-grow-1 flex-column justify-content-center align-items-center">
        <div class="mb-2">
          <h5
            v-if="title"
            class="mb-0 font-weight-bolder toastification-title text-danger"
            v-text="title"
          />
          <template v-if="typeof text === 'string'">
            <small
              class="d-inline-block text-body"
              v-text="text"
            />
          </template>
          <template v-if="Array.isArray(text)">
            <small
              v-for="(t, i) in text"
              :key="i"
              class="d-block text-body"
              v-text="t"
            />
          </template>
        </div>
        <div class="d-flex flex-row justify-content-between align-items-center">
          <b-button
            variant="success"
            type="button"
            block
            @click="confirm"
          >
            {{ okText }}
          </b-button>
          <b-button
            v-if="!hideClose"
            variant="danger"
            type="button"
            block
            class="m-0 ml-1"
            @click="$emit('close-toast')"
          >
            {{ cancelText }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar, BButton } from 'bootstrap-vue'
import { AlertTriangleIcon } from 'vue-feather-icons'
import VueI18n from '@/libs/i18n'

export default {
  components: {
    BAvatar,
    AlertTriangleIcon,
    BButton,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    text: {
      type: [String, Array],
      default: null,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
    onConfirm: {
      type: Function,
      default: async () => {},
    },
  },
  computed: {
    okText() {
      return VueI18n.t('components.button.labels.yes')
    },
    cancelText() {
      return VueI18n.t('components.button.labels.cancel')
    },
  },
  methods: {
    async confirm() {
      await this.onConfirm()
      this.$emit('close-toast')
    },
  },
}
</script>

<style lang="scss" scoped>
.toastification-close-icon,
.toastification-title {
  line-height: 26px;
}

.toastification-title {
  color: inherit;
}
</style>

<template>
  <div>
    <b-row class="mb-1">
      <b-col
        v-if="isTeacher"
        cols="12"
        xl="3"
        md="5"
        class="mb-1 mb-md-0"
      >
        <v-select
          id="subject"
          v-model="studentId"
          :options="students"
          clearable
          label="username"
          :reduce="student => student.id"
          placeholder="Выберите ученика"
          class="d-block w-100 "
        >
          <template #option="user">
            <span>{{ getStudentName(user) }}</span>
          </template>
          <template #selected-option="user">
            <span>{{ getStudentName(user) }}</span>
          </template>
          <template slot="no-options">
            {{ $t('components.select.no-items') }}
          </template>
        </v-select>
      </b-col>
      <b-col />
      <b-col
        cols="12"
        md="auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            :to="{name: 'cabinet.sessions.add'}"
            variant="primary"
          >
            <feather-icon icon="PlusIcon" />
            Создать урок
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <sessions-list
          v-if="isTeacher"
          filters
          :per-page="10"
          no-title
          :student-id="studentId"
        />
        <student-sessions-list
          v-else
          filters
          :per-page="10"
          no-title
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import SessionsList from '@/components/dashboard/SessionsList.vue'
import { teacherRole, userRoles } from '../../store/user'
import StudentSessionsList from '@/components/dashboard/StudentSessionsList.vue'
import { getUserFullName } from '../../helpers'

export default {
  name: 'Lessons',
  components: {
    StudentSessionsList,
    SessionsList,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  data: () => ({
    studentId: null,
    students: [],
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
  },
  async mounted() {
    await this.getStudents()
  },
  methods: {
    getStudentName(user) {
      return getUserFullName(user)
    },
    async getStudents() {
      const { items } = await this.$http.get('/user/current-teacher-student-index', {
        params: { expand: 'usersStudent' },
      })
      this.students = items
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <b-card>
    <div>
      <h3 class="mb-1">
        Ученик
      </h3>
      <b-row>
        <b-col
          v-for="sessionStudent in students"
          :key="sessionStudent.id"
          cols="12"
          md="6"
          xl="3"
        >
          <session-student-card
            no-body
            :session-student-id="sessionStudent.id"
            :student="sessionStudent.student"
          />
        </b-col>
      </b-row>

      <b-alert
        v-if="students.length === 0"
        variant="warning"
        show
        class="m-0"
      >
        <div class="alert-body">
          Нет назначенных учеников
        </div>
      </b-alert>

      <div class="thin-divider my-1" />
    </div>
    <div v-if="lessonsHistory">
      <h3>Темы</h3>
      <div>
        {{ lessonsHistory }}
      </div>

      <div class="thin-divider my-1" />
    </div>
    <!--    <h3>Домашнее задание</h3>-->
    <!--    <div>-->
    <!--      <div-->
    <!--        v-if="!hometask"-->
    <!--        class="d-flex align-items-center"-->
    <!--      >-->
    <!--        <span-->
    <!--          v-if="students.length"-->
    <!--          class="mr-1"-->
    <!--        >-->
    <!--          Не задано-->
    <!--        </span>-->
    <!--        <b-button-->
    <!--          v-if="students.length"-->
    <!--          variant="flat-primary"-->
    <!--          size="sm"-->
    <!--          :disabled="addingHometask"-->
    <!--          @click="addHometask"-->
    <!--        >-->
    <!--          <div v-if="!addingHometask">-->
    <!--            <feather-icon-->
    <!--              class="mr-50"-->
    <!--              icon="PlusIcon"-->
    <!--            />-->
    <!--            <span>Добавить</span>-->
    <!--          </div>-->
    <!--          <div-->
    <!--            v-else-->
    <!--            class="d-flex align-items-center"-->
    <!--          >-->
    <!--            <b-spinner small />-->
    <!--            <span class="ml-50">Добавление...</span>-->
    <!--          </div>-->
    <!--        </b-button>-->
    <!--        <b-alert-->
    <!--          v-else-->
    <!--          variant="warning"-->
    <!--          show-->
    <!--          class="m-0"-->
    <!--        >-->
    <!--          <div class="alert-body">-->
    <!--            К сессии не присоединялись ученики-->
    <!--          </div>-->
    <!--        </b-alert>-->
    <!--      </div>-->
    <!--      <div-->
    <!--        v-else-->
    <!--        class="d-flex align-items-center"-->
    <!--      >-->
    <!--        <span class="mr-1">от {{ hometask.created | dateTime }}</span>-->
    <!--        <b-button-->
    <!--          variant="flat-primary"-->
    <!--          size="sm"-->
    <!--          :to="{name: 'cabinet.hometasks.task', params: {task_id: hometask.id}}"-->
    <!--          target="_blank"-->
    <!--        >-->
    <!--          <span>Перейти</span>-->
    <!--          <feather-icon-->
    <!--            class="ml-50"-->
    <!--            icon="ArrowRightIcon"-->
    <!--          />-->
    <!--        </b-button>-->
    <!--      </div>-->
    <!--    </div>-->

    <div v-if="exercises.length">
      <div class="thin-divider my-1" />

      <h3>Выполненные упражнения</h3>
      <b-row
        v-for="(exercise, key) in exercises"
        :key="exercise.id"
      >
        <b-col
          cols="12"
          md="4"
          xl="4"
        >
          {{ key + 1 }}. {{ exercise.exercises.name }}
        </b-col>
        <b-col
          cols="12"
          md="4"
          xl="3"
        >
          выполнено за {{ exerciseDuration(exercise) }}
        </b-col>
        <b-col
          cols="12"
          md="4"
          xl="3"
        >
          <b-progress
            show-progress
            :value="exercise.correct_percentage"
            max="100"
            :title="`${parseInt(exercise.correct_percentage, 10)}% правильных ответов`"
            :variant="percentageColor(exercise.correct_percentage)"
            striped
          />
        </b-col>
      </b-row>
    </div>

    <div v-if="images.length">
      <div class="thin-divider my-1" />

      <b-row>
        <b-col
          v-for="image in images"
          :key="image.id"
          cols="6"
          md="3"
          xl="2"
        >
          <a
            :href="image.store.full_url"
            target="_blank"
          >
            <b-img-lazy
              fluid-grow
              center
              class="rounded"
              :src="image.store.full_url"
            />
          </a>
        </b-col>
      </b-row>
    </div>

    <div class="mt-1">
      <b-button
        size="sm"
        class="mr-50"
        variant="outline-primary"
        :to="{name: 'cabinet.session', params: {session_id: session.id}}"
      >
        Подробнее
      </b-button>
      <b-button
        size="sm"
        variant="outline-secondary"
        @click="$emit('hide')"
      >
        Свернуть
      </b-button>
    </div>

  </b-card>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BImgLazy, BProgress,
  BAlert,
  // BSpinner,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import { diffBetweenDates } from '@/helpers'
import SessionStudentCard from '@/components/session/editor/students/SessionStudentCard.vue'

export default {
  name: 'SessionInfo',
  components: {
    SessionStudentCard,
    BCard,
    BButton,
    BRow,
    BCol,
    BImgLazy,
    BProgress,
    BAlert,
    // BSpinner,
  },
  props: {
    session: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    addingHometask: false,
  }),
  computed: {
    hometask() {
      return this.session.homeTasks[0]
    },
    images() {
      return this.session.lessonSessionsStores
    },
    exercises() {
      return this.session.lessonSessionExercises
    },
    students() {
      return this.session.sessionStudents
    },
    lessonsHistory() {
      return this.session.lessonSessionLessonsHistories.map(hist => hist.lesson.name).join(', ')
    },
  },
  methods: {
    ...mapActions({
      addHometaskToSession: 'videoStream/addHometaskToSession',
    }),
    async addHometask() {
      this.addingHometask = true
      const tasks = await this.addHometaskToSession(this.session)
      this.session.homeTasks = tasks
      setTimeout(() => {
        this.addingHometask = false
      }, 2000)
    },
    exerciseDuration(exercise) {
      return diffBetweenDates(exercise.started_at, exercise.finished_at)
    },
    percentageColor(value) {
      const val = parseInt(value, 10)
      if (val === 0) return 'danger'
      if (val <= 75) return 'warning'
      return 'success'
    },
  },
}
</script>

<style scoped>

</style>

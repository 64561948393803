<template>
  <div>
    <h2
      v-if="!noTitle"
      class="mb-2"
    >
      История уроков
    </h2>
    <b-card
      no-body
      class="mb-0"
    >
      <table-header
        v-if="filters"
        search
        :filter="filter"
        :search-updated="getSessions"
        :students="students"
        :statuses="statuses"
      />
      <b-table
        ref="teachersTable"
        striped
        class="rounded-top"
        :items="sessions"
        responsive
        :fields="tableFields"
        primary-key="id"
        show-empty
        :empty-text="$t('page.students.search.labels.no-items')"
        :sort-by.sync="filter.sortBy"
        :sort-desc.sync="filter.sortDesc"
        hover
        @row-clicked="showMore"
      >
        <template #cell(status)="{item}">
          <status-column :status="item.status" />
        </template>
        <template #cell(actions)="{item}">
          <div class="d-flex flex-row justify-content-end align-items-center">
            <b-button
              v-if="sessionIsOnline(item)"
              size="sm"
              class="mr-50"
              variant="flat-primary"
              :to="{name: 'cabinet.sessions.online', params: {session_id: item.id}}"
            >
              <feather-icon
                icon="PlayIcon"
                class="mr-50"
              />
              <span>Присоединиться</span>
            </b-button>
            <b-button
              variant="flat-primary"
              class="ml-50 btn-icon rounded-circle"
              size="sm"
              :to="{name: 'cabinet.session', params: {session_id: item.id}}"
            >
              <feather-icon
                size="15"
                icon="ExternalLinkIcon"
              />
            </b-button>
            <!--            <b-button-->
            <!--              variant="flat-primary"-->
            <!--              class="btn-icon rounded-circle"-->
            <!--              size="sm"-->
            <!--              @click="toggleDetails"-->
            <!--            >-->
            <!--              <feather-icon-->
            <!--                size="15"-->
            <!--                :icon="detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'"-->
            <!--              />-->
            <!--            </b-button>-->
            <b-dropdown
              size="sm"
              variant="flat-secondary"
              toggle-class="text-decoration-none btn-icon rounded-circle ml-1"
              no-caret
              boundary="viewport"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                />
              </template>

              <b-dropdown-item :to="{name: 'cabinet.session', params: {session_id: item.id}}">
                <feather-icon
                  class="mr-50"
                  icon="InfoIcon"
                />
                Подробнее
              </b-dropdown-item>

              <b-dropdown-item
                v-if="sessionIsOnline(item)"
                :to="{name: 'cabinet.sessions.online', params: {session_id: item.id}}"
              >
                <feather-icon
                  icon="PlayIcon"
                  class="mr-50"
                />
                <span>Присоединиться</span>
              </b-dropdown-item>

            </b-dropdown>
          </div>
        </template>
        <template #row-details="{item, toggleDetails}">
          <session-info
            :session="item"
            @hide="toggleDetails"
          />
        </template>
      </b-table>
      <table-footer
        :pagination-updated="getSessions"
        :pagination="pagination"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BButton, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import TableFooter from '@/components/page-elements/table/TableFooter.vue'
import { diffBetweenDates } from '@/helpers'
import { studentRole, teacherRole, userRoles } from '../../store/user'
import TableHeader from '@/components/page-elements/table/TableHeader.vue'
import SessionInfo from '@/components/dashboard/session/SessionInfo.vue'
import {
  SESSION_STATUS_ACTIVE,
  SESSION_STATUS_ACTIVE_LABEL, SESSION_STATUS_CANCELED, SESSION_STATUS_CANCELED_LABEL, SESSION_STATUS_ONLINE,
  SESSION_STATUS_PASSED,
  SESSION_STATUS_PASSED_LABEL, SESSION_STATUS_RESCHEDULED, SESSION_STATUS_RESCHEDULED_LABEL,
} from '@/shared/constants'
import StatusColumn from '@/components/session/table/StatusColumn.vue'

export default {
  name: 'StudentSessionsList',
  components: {
    StatusColumn,
    SessionInfo,
    TableHeader,
    TableFooter,
    BCard,
    BTable,
    BButton,
    BDropdown,
    BDropdownItem,
  },
  props: {
    filters: {
      type: Boolean,
      default: false,
    },
    perPage: {
      type: Number,
      default: 5,
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    sessions: [],
    pagination: {
      perPage: 5,
      perPageOptions: [5, 10, 15, 30],
      totalCount: null,
      currentPage: 1,
    },
    filter: {
      search: '',
      sortBy: 'order',
      sortDesc: true,
      statuses: null,
    },
    students: [],
    statuses: [
      {
        value: null,
        label: 'Все',
      },
      {
        value: SESSION_STATUS_PASSED,
        label: SESSION_STATUS_PASSED_LABEL,
      },
      {
        value: SESSION_STATUS_ACTIVE,
        label: SESSION_STATUS_ACTIVE_LABEL,
      },
      {
        value: SESSION_STATUS_RESCHEDULED,
        label: SESSION_STATUS_RESCHEDULED_LABEL,
      },
      {
        value: SESSION_STATUS_CANCELED,
        label: SESSION_STATUS_CANCELED_LABEL,
      },
    ],
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    isStudent() {
      return this.userRole === studentRole
    },
    sortBy() {
      if (!this.filter.sortBy) return null
      let { sortBy } = this.filter
      if (this.filter.sortDesc) sortBy = `-${sortBy}`
      else sortBy = `+${sortBy}`
      return sortBy
    },
    tableFields() {
      return [
        {
          key: 'order',
          label: this.$t('page.sessions_list.table.labels.id'),
          sortable: true,
        },
        {
          key: 'starts',
          label: this.$t('page.sessions_list.table.labels.created_at'),
          sortable: true,
          formatter: (value, key, session) => (session.starts ? this.$options.filters.dateTime(session.starts) : this.$t('no_data')),
        },
        {
          key: 'name',
          label: this.$t('page.sessions_list.table.labels.name'),
          sortable: true,
          formatter: (value, key, session) => (session.name ? session.name : this.$t('no_data')),
        },
        {
          key: 'duration',
          label: this.$t('page.sessions_list.table.labels.duration'),
          sortable: false,
          formatter: (value, key, session) => (session.finished ? diffBetweenDates(session.starts, session.finished) : this.$t('no_data')),
        },
        {
          key: 'status',
          label: 'Состояние',
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
        },
      ]
    },
  },
  watch: {
    sortBy() {
      this.getSessions()
    },
  },
  async created() {
    this.pagination.perPage = this.perPage
    await this.getSessions()
  },
  methods: {
    async getSessions() {
      const expand = [
        'sessionStudents.student.usersStudent',
        'lessonSessionsStores.store',
        'lessonSessionExercises.exercises',
        'lessonSessionLessonsHistories.lesson',
      ]
      const params = {
        sort: this.sortBy,
        page: this.pagination.currentPage,
        limit: this.pagination.perPage,
        name: this.filter.search,
        status: this.filter.statuses,
        expand: expand.join(','),
      }
      const { items, _meta } = await this.$http.get('/sessions/current-student-index', { params })
      this.pagination.totalCount = _meta.totalCount
      this.sessions = items
    },
    sessionIsOnline(session) {
      return session.status === SESSION_STATUS_ONLINE
    },
    showMore(record) {
      this.$router.push({ name: 'cabinet.session', params: { session_id: record.id } })
    },
  },
}
</script>

<style scoped>

</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.noTitle)?_c('h2',{staticClass:"mb-2"},[_vm._v(" История уроков ")]):_vm._e(),_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[(_vm.filters)?_c('table-header',{attrs:{"search":"","filter":_vm.filter,"search-updated":_vm.getSessions,"statuses":_vm.onlyUpcoming ? [] : _vm.statuses}}):_vm._e(),_c('b-table',{ref:"teachersTable",staticClass:"rounded-top",attrs:{"striped":"","items":_vm.sessions,"responsive":"","fields":_vm.tableFields,"primary-key":"id","show-empty":"","empty-text":"Нет уроков","sort-by":_vm.filter.sortBy,"sort-desc":_vm.filter.sortDesc,"hover":""},on:{"update:sortBy":function($event){return _vm.$set(_vm.filter, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.filter, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.filter, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.filter, "sortDesc", $event)},"row-clicked":_vm.showMore},scopedSlots:_vm._u([{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('status-column',{attrs:{"status":item.status}})]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row justify-content-end align-items-center"},[(_vm.sessionIsActive(item))?_c('b-button',{attrs:{"size":"sm","variant":"flat-primary","to":{name: 'cabinet.sessions.online', params: {session_id: item.id}}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlayIcon"}}),_c('span',[_vm._v("Начать")])],1):_vm._e(),_c('b-button',{staticClass:"ml-50 btn-icon rounded-circle",attrs:{"variant":"flat-primary","size":"sm","to":{name: 'cabinet.session', params: {session_id: item.id}}}},[_c('feather-icon',{attrs:{"size":"15","icon":"EditIcon"}})],1),(!_vm.onlyUpcoming)?_c('b-dropdown',{attrs:{"size":"sm","variant":"flat-secondary","toggle-class":"text-decoration-none btn-icon rounded-circle ml-1","no-caret":"","boundary":"viewport"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{attrs:{"icon":"MoreVerticalIcon"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":{name: 'cabinet.session', params: {session_id: item.id}}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"InfoIcon"}}),_vm._v(" Подробнее ")],1),(_vm.sessionIsActive(item))?_c('b-dropdown-item',{attrs:{"to":{name: 'cabinet.sessions.online', params: {session_id: item.id}}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlayIcon"}}),_c('span',[_vm._v("Начать")])],1):_vm._e(),_c('b-dropdown-divider'),_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteSessionConfirm(item.id)}}},[_c('feather-icon',{staticClass:"mr-50 text-danger",attrs:{"icon":"XIcon"}}),_c('span',{staticClass:"text-danger"},[_vm._v("Удалить")])],1)],1):_vm._e()],1)]}},{key:"row-details",fn:function(ref){
var item = ref.item;
var toggleDetails = ref.toggleDetails;
return [_c('session-info',{attrs:{"session":item},on:{"hide":toggleDetails}})]}}])}),_c('table-footer',{attrs:{"pagination-updated":_vm.getSessions,"pagination":_vm.pagination}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }